import {AnyAction} from 'redux'
import {CHANGE_EVENT} from '../actions/event'
import {TicketWithFormattedSalePeriod} from '../types'

export const tickets = (state: TicketWithFormattedSalePeriod[] = [], action: AnyAction) => {
  switch (action.type) {
    case CHANGE_EVENT:
      return action.payload.tickets
    default:
      return state
  }
}
